// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"ed688d90300bf531ad48bf5d60b5bd3a9eb5e6db"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

const VERCEL_ENV = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV
const isProduction = VERCEL_ENV === 'production'

Sentry.init({
  dsn: 'https://5e378655871d4c55a3a70895e0875c4a@o84246.ingest.us.sentry.io/1388078',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: isProduction ? 0.1 : 1.0,

  denyUrls: ['http://localhost:3000'],
  enabled: window.location.hostname !== 'localhost',

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: isProduction ? 0.2 : 1.0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        'https://dashboard-api.staging.players1st.sport',
        'https://dashboard-api.players1st.sport',
      ],
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
})
